.App {
  display: flex;
  min-height: 100vh;
}


.main-content {
  width: 100%;
  padding: 20px;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
